import addReset from "@utils/storeUtils/addReset";
import writableExtendable from "@utils/storeUtils/writableExtendable";
import type { Theme } from "@utils/types";
import config from "./config";

const theme = writableExtendable<Theme>({
  primary: "#4c33ff",
  marginBottom: "1rem",
  marginRight: "1rem",
}).extend(addReset);

config.subscribe($config => {
  theme.set({
    primary: $config.headerColor,
    marginBottom: $config.marginBottom,
    marginRight: $config.marginRight,
  });
});

export default theme;

import addAsyncUpdate from "@utils/storeUtils/addAsyncUpdate";
import addReset from "@utils/storeUtils/addReset";
import bindStorage from "@utils/storeUtils/bindStorage";
import writableExtendable from "@utils/storeUtils/writableExtendable";
import type { ILivechatMessage, TimestampKey } from "@utils/types";
import localForage from "localforage";
import { extendPrototype as LocalForageExtendPrototype } from "localforage-observable";
import marked from "marked";
import { t } from "svelte-i18n";
import { get } from "svelte/store";
import Observable from "zen-observable";
import type { RootModel } from "../model";

const localForageObservable = LocalForageExtendPrototype(localForage);
localForageObservable.configObservables({
  crossTabNotification: false,
});

localForage.newObservable.factory = subscribeFn => new Observable(subscribeFn);

export default class MessagesViewModel {
  messages = writableExtendable(
    new Map<TimestampKey, ILivechatMessage>()
  ).extend(addAsyncUpdate);

  private messagesTable: LocalForageWithObservableMethods;

  private messagesTableIsAlive = writableExtendable(false)
    .extend(addReset)
    .extend(addAsyncUpdate)
    .extend(bindStorage("livechat:messages-table-is-alive"));

  constructor(private rootModel: RootModel) {
    this.messagesTable = localForageObservable.createInstance({
      name: "b2chatdb",
      storeName: "messages",
      description: "livechat messages table",
      version: 2,
    });

    this.messagesTable
      .ready()
      .then(async () => {
        this.messagesTable.configObservables({ crossTabNotification: true });

        const observable = this.messagesTable.newObservable({
          setItem: true,
          removeItem: true,
          clear: true,
          crossTabNotification: true,
        });

        await this.messagesTableIsAlive.asyncUpdate(async $valid => {
          if (!$valid) await this.reset();
          return true;
        });

        await this.messages.asyncUpdate(async $messages => {
          await this.messagesTable.iterate<ILivechatMessage, any>(
            (message, key) => void $messages.set(+key, message)
          );
          return $messages;
        });

        observable.subscribe({
          next: change => {
            if (change.success) {
              switch (change.methodName) {
                case "setItem":
                  return this.messages.update($messages =>
                    $messages.set(+change.key, change.newValue)
                  );
                case "removeItem":
                  return this.messages.update(
                    $messages => ($messages.delete(+change.key), $messages)
                  );
                case "clear":
                  return this.messages.update(
                    $messages => ($messages.clear(), $messages)
                  );
              }
            }
          },
          error(err) {
            console.error("Found an error!", err);
          },
          complete() {
            console.error("Observable destroyed!");
          },
        });
      })
      .catch(e => {
        console.error("LocalForage isn't ready", e);
      });
  }

  async getOrder(): Promise<TimestampKey> {
    try {
      return this.messagesTable.length();
    } catch (e) {
      console.error(e);
      return get(this.messages).size;
    }
  }

  async put(key: TimestampKey, message: ILivechatMessage) {
    const { messagesTable } = this;

    if (message.type === "set_agent") {
      this.rootModel.agent.set({
        avatar: message.avatar || "",
        name: message.name || "",
      });
      message = {
        from: "incoming",
        type: "set_agent",
        timestamp: message.timestamp,
      };
    } else if (message.type === "agent_closed_chat") {
      this.rootModel.agent.reset();
      message = {
        from: "incoming",
        type: "agent_closed_chat",
        timestamp: message.timestamp,
      };
    }

    if (message.from === "incoming") {
      const { avatar, name } = this.rootModel.agent.read();
      if (avatar && name) {
        message.name = name;
        message.avatar = avatar;
      }
    }

    try {
      message.order ??= await this.getOrder();

      if (message.text && !message.textHtml) {
        message.textHtml = marked(message.text);
      }

      await messagesTable.setItem(`${key}`, message);
    } catch (e) {
      console.error(e);
      this.messages.update($messages =>
        $messages.set(message.timestamp, message)
      );
    }
  }

  async update(key: TimestampKey, changes: Partial<ILivechatMessage>) {
    try {
      const message = await this.messagesTable.getItem<ILivechatMessage>(
        `${key}`
      );
      if (message !== null) {
        await this.messagesTable.setItem(`${key}`, {
          ...message,
          ...changes,
        });
      }
    } catch (e) {
      console.error(e);
      this.messages.update($messages => {
        if ($messages.has(key))
          $messages.set(key, {
            ...$messages.get(key)!,
            ...changes,
          });
        return $messages;
      });
    }
  }

  async clear() {
    try {
      await this.messagesTable.clear();
    } catch (e) {
      console.error(e);
      this.messages.update($messages => ($messages.clear(), $messages));
    }
  }

  async reset() {
    this.rootModel.agent.reset();
    await this.clear();
    const $t = get(t);
    const welcomeMessage: ILivechatMessage = {
      from: "incoming",
      timestamp: Date.now(),
      type: "welcome",
      text: $t("welcome-message"),
    };
    await this.put(welcomeMessage.timestamp, welcomeMessage);
  }
}

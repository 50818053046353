export default () => {
  return Promise.race([
    new Promise<void>(resolve =>
      window.addEventListener("load", () => resolve(), { once: true })
    ),
    new Promise<void>(resolve =>
      window.document.addEventListener("DOMContentLoaded", () => resolve(), {
        once: true,
      })
    ),
    new Promise<void>(resolve => setTimeout(resolve, 1000)),
  ]);
};

import addReadWhen from "@utils/storeUtils/addReadWhen";
import addReset from "@utils/storeUtils/addReset";
import bindStorage from "@utils/storeUtils/bindStorage";
import writableExtendable from "@utils/storeUtils/writableExtendable";

export default writableExtendable(false)
  .extend(addReset)
  .extend(addReadWhen)
  .extend(store => ({
    toggle() {
      store.update($open => !$open);
    },
  }))
  .extend(bindStorage("livechat:window-open"));

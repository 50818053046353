import logger from "@utils/logger";
import noop from "@utils/noop";
import type { Data, DataMap, GetEventTarget, Unsubscribe } from "./types";

///<reference lib="webworker" />
interface ExtendableMessageEvent<T> {
  data: T;
}

export default function createMessageListener<D extends DataMap = DataMap>(
  eventTarget: GetEventTarget,
  sourceOrigin?: string
): MessageListenerType<D>;

export default function createMessageListener(
  eventTarget: GetEventTarget,
  sourceOrigin?: string
) {
  return (name: string, listener: Function, options?: { once: boolean }) => {
    try {
      const target = eventTarget();
      if (!target) throw new Error("EventTarget unavailable");

      const listener_ = (event: MessageEvent) => {
        if (sourceOrigin && event.origin !== sourceOrigin) return;

        if (event.data?.name === name) {
          logger.debug("onMessage", event.data);
          listener(event);
          options?.once && unsubscribe();
        }
      };

      target.addEventListener("message", listener_ as any);

      const unsubscribe = () =>
        target.removeEventListener("message", listener_ as any);

      return unsubscribe;
    } catch (e) {
      console.error(e);
      return noop;
    }
  };
}

export type MessageListenerType<D extends DataMap> = <Name extends keyof D>(
  name: Name,
  listener: (event: ExtendableMessageEvent<Data<Name, D[Name]>>) => void,
  options?: { once: boolean }
) => Unsubscribe;

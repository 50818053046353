import noop from "@utils/noop";
export default class LivechatError extends Error {
  data: any;

  constructor(message?: string, data?: any) {
    super(message ?? "Error");
    this.name = "LivechatError";
    this.data = data;
    this.message = message || "Error";
  }

  static from(error: Error): LivechatError {
    if (error instanceof LivechatError) {
      return error;
    }
    error.name = "LivechatError";
    return error as LivechatError;
  }

  static async assertResponse<T = any>(response: Response): Promise<T> {
    const data: T = await response.json().catch(noop);

    const dataStatus = ((): string => {
      try {
        if ("status" in data) {
          return (<any>data).status || "";
        }
      } catch {}
      return "";
    })();

    if (!response.ok || dataStatus.toLocaleLowerCase().startsWith("error")) {
      throw new LivechatError(`${response.statusText} ${dataStatus}`, {
        status: response.status,
        data,
      });
    }

    return data;
  }
}

import addReset from "@utils/storeUtils/addReset";
import bindStorage from "@utils/storeUtils/bindStorage";
import writableExtendable from "@utils/storeUtils/writableExtendable";
import type { Agent } from "@utils/types";

export default writableExtendable<Agent>({
  avatar: "",
  name: "",
})
  .extend(addReset)
  .extend(bindStorage("livechat:agent"));

<script lang="ts">
  import { fly } from "svelte/transition";
  export let src = "";
  export let open = false;
</script>

{#if open && src}
  <div transition:fly class="backdrop" on:click={() => (open = false)}>
    <img class="preview" {src} alt="" />
  </div>
{/if}

<style lang="postcss">
  .backdrop {
    position: fixed;
    z-index: 1000101;
    @apply cursor-pointer flex items-center justify-center inset-0 bg-gray-50 bg-opacity-30;
  }
  .preview {
    max-width: 800px;
    max-height: 600px;
    @apply object-cover rounded-lg overflow-hidden shadow-2xl m-4 select-none;
  }
</style>

<script lang="ts">
  import shadowRoot from "@utils/svelteActions/shadowRoot";
  import store from "src_launcher/store";
  import { onMount } from "svelte";

  let self: HTMLDivElement;

  let className = "";
  export { className as class };

  const {
    windowStyle: { cssString },
    windowOpen,
  } = store.rootModel;

  onMount(async () => {
    try {
      await windowOpen.readWhen($open => $open);
      const LivechatWindowModule = await import(
        /* webpackChunkName: "livechat_window" */
        "src_window/main"
      );
      new LivechatWindowModule.default({
        target: self.shadowRoot as any as Element,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  });
</script>

<!-- `contenteditable="true"` That hack avoid that Procinal.com cancel keypress event with code 'Space' -->
<div
  bind:this={self}
  use:shadowRoot
  id="livechat-window"
  class={className}
  style={$cssString}
  data-open={$windowOpen}
  contenteditable={location.hostname.includes("procinal")}
/>

<style lang="postcss" scoped>
  #livechat-window {
    z-index: 1000100 !important;
    position: fixed;
    right: 0;
    bottom: 0;
    overflow: hidden;

    width: 100vw;
    max-width: calc(theme("spacing.96") + theme("spacing.11"));
    height: calc(
      var(--b2chat-vh) * 100 - var(--b2chat-window-bottom-gap) - 0.1rem
    );
    max-height: 0px;

    margin-bottom: var(--b2chat-window-bottom-gap);
    margin-right: var(--b2chat-window-right-gap);

    border: none !important;
    outline: none !important;
    border-radius: theme("borderRadius.DEFAULT");
    box-shadow: 0px 0px 30px theme("colors.gray.300") !important;
    visibility: hidden;
    opacity: 0;
  }

  #livechat-window[data-open="true"] {
    animation: 200ms ease-in 0s 1 normal forwards running show-window;
  }

  #livechat-window[data-open="false"] {
    animation: 100ms ease-out 0s 1 normal forwards running hide-window;
  }

  @keyframes show-window {
    from {
      pointer-events: none;
      visibility: visible;
      opacity: 0;
      transform: translateY(50px) scale(0.95);
    }
    99.99% {
      pointer-events: none;
      visibility: visible;
      opacity: 1;
    }
    to {
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes hide-window {
    from {
      pointer-events: none;
      visibility: visible;
      opacity: 1;
    }
    99.99% {
      pointer-events: none;
      visibility: visible;
      opacity: 0;
      transform: translateY(10%) scale(0.95);
    }
    to {
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
    }
  }
</style>

import writableExtendable from "@utils/storeUtils/writableExtendable";
import { derived } from "svelte/store";

export type WindowStyle = {
  [P in `${"min-" | "max-" | ""}${"width" | "height"}`]?: string;
};

export default writableExtendable<WindowStyle>({}).extend(store => ({
  cssString: derived(store, $store => {
    return Object.entries($store).reduce(
      (str, [prop, value]) => `${prop}:${value};${str}`,
      ""
    );
  }),
}));

import domContentLoaded from "@utils/domContentLoaded";
import addReset from "@utils/storeUtils/addReset";
import writableExtendable from "@utils/storeUtils/writableExtendable";

const browserTabBadge = () =>
  writableExtendable(0)
    .extend(addReset)
    .extend(store => {
      const documentTitle = window.document.title;

      let timer: NodeJS.Timer;
      let tick = 0;

      domContentLoaded().then(() => {
        store.subscribe($badge => {
          if ($badge > 0) {
            const badgeText = $badge > 9 ? "+9" : $badge.toString();

            clearInterval(timer);
            timer = setInterval(() => {
              if (++tick % 2 === 0) {
                window.document.title = `[${badgeText}] ${documentTitle}`;
              } else {
                window.document.title = documentTitle;
              }
            }, 800);
          } else {
            clearInterval(timer);
            tick = 0;
            window.document.title = documentTitle;
          }
        });
      });

      return {
        ...store,
        increase() {
          store.update($value => $value + 1);
        },
        set(value: number) {
          store.set(value < 0 ? 0 : value);
        },
      };
    });

export default browserTabBadge;
export type BrowserTabBadge = ReturnType<typeof browserTabBadge>;

<script lang="ts">
  import noop from "@utils/noop";
  import ripple from "@utils/svelteActions/ripple";
  import { fade } from "svelte/transition";

  export let src = "";
  export let style = "";
</script>

<div id="livechat-launcher" {style}>
  <picture
    tabindex={-1}
    transition:fade={{ duration: 200 }}
    on:drag|preventDefault={noop}
    on:dragstart|preventDefault={noop}
    on:click
  >
    <source srcset={src} />
    <img {src} alt="" />
    <div class="ripple-area" use:ripple />
  </picture>
  <slot />
</div>

<style lang="postcss">
  picture {
    display: flex;
    border-radius: 2px;
    outline: none;
    filter: drop-shadow(0px 0px 2px theme("colors.gray.50"));
    position: fixed;
    z-index: 1000000 !important;
    right: var(--b2chat-margin-right);
    bottom: var(--b2chat-margin-bottom);
    cursor: pointer;
    transition: filter 150ms;
  }
  picture .ripple-area {
    @apply absolute inset-0 rounded overflow-hidden text-gray-50 select-none;
  }
  picture:hover {
    filter: drop-shadow(0px 0px 10px theme("colors.gray.200"));
  }
  picture source,
  picture img {
    object-fit: cover;
    max-width: none;
    max-height: 100px;
    width: auto;
    height: auto;

    cursor: pointer;
    background-color: transparent;
    outline: none;
  }
</style>

import agent from "./agent";
import config from "./config";
import mediaPreview from "./mediaPreview";
import { prechat } from "./prechat";
import session from "./session";
import theme from "./theme";
import windowFocus from "./windowFocus";
import windowOpen from "./windowOpen";
import windowStyle from "./windowStyle";

const rootModel = {
  config,
  prechat,
  session,
  theme,
  agent,
  windowOpen,
  windowFocus,
  windowStyle,
  mediaPreview,
};

export type RootModel = typeof rootModel;

export default rootModel;

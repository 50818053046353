import noop from "./noop";
import addReset from "./storeUtils/addReset";
import writableExtendable from "./storeUtils/writableExtendable";

type LoggerFunction = (tag: string, ...messages: any[]) => void;

const context = globalThis.constructor.name;

function createLoggger(level: "info" | "debug"): LoggerFunction {
  return (tag, ...messages) => {
    console[level](
      `${context} %c${tag}`,
      "color: #BFDBFE; font-weight: bold;",
      ...messages
    );
  };
}

export default (() => {
  const logger = {
    debug: <LoggerFunction>noop,
    info: <LoggerFunction>noop,
    enabled: writableExtendable(false).extend(addReset),
  };
  logger.enabled.subscribe($enabled => {
    logger.debug = $enabled ? createLoggger("debug") : noop;
    logger.info = $enabled ? createLoggger("info") : noop;
  });

  return logger;
})();

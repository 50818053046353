import type { Writable } from "./writableExtendable";

type Unwrap<W> = W extends Writable<infer T> ? T : unknown;

const addReadWhen = <W extends Writable>(store: W) => {
  return {
    readWhen(predicate: ($value: Unwrap<W>) => boolean) {
      return new Promise<Unwrap<W>>(resolve => {
        const off = store.subscribe($store => {
          if (predicate($store as any)) {
            setTimeout(() => {
              off();
              resolve($store as any);
            }, 0);
          }
        });
      });
    },
  };
};

export default addReadWhen;

import { SERVICES_URL } from "@utils/constants";
import LivechatError from "@utils/LivechatError";
import type { LivechatConfigData } from "@utils/types";

async function fetchLivechatConfig(token: string): Promise<LivechatConfigData> {
  try {
    const response = await fetch(
      `${SERVICES_URL}/live/api/v1/config/${token}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
        mode: "cors",
      }
    );
    return await LivechatError.assertResponse(response);
  } catch (e) {
    const error = LivechatError.from(e);
    if (error?.data?.status === 404) {
      console.error(`${error.name}: '${token}' token isn't recognized.`);
    } else {
      console.error(error);
    }
    throw error;
  }
}

export default fetchLivechatConfig;

import writableExtendable from "@utils/storeUtils/writableExtendable";

const windowFocus = writableExtendable(false).extend(store => {
  window.addEventListener("focus", () => {
    try {
      const livechatWindowEl =
        document.querySelector<HTMLDivElement>("#livechat-window");
      if (livechatWindowEl?.contains(document.activeElement)) {
        store.set(true);
      }
    } catch {}
  });

  window.addEventListener("blur", () => {
    store.set(false);
  });
});

export default windowFocus;

/// <reference lib="dom" />
/// <reference types="node" />

import logger from "@utils/logger";
import bindStorage from "@utils/storeUtils/bindStorage";
import { setupB2ChatInstance } from "./b2chat";

logger.enabled.extend(bindStorage("livechat:debug-enabled"));

setupB2ChatInstance();

export default {};

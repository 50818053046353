<script lang="ts">
  import b2chatGray from "@utils/b2chatGray";
  import browser from "@utils/browser";
  import debounce from "@utils/debounce";
  import {
    Color,
    ColorContrastCalc,
  } from "color-contrast-calc/lib/color-contrast-calc";
  import { onMount } from "svelte";
  import store from "./store";

  const theme = store.rootModel.theme;

  function setCSSVar(varName: string, value: string) {
    document.documentElement.style.setProperty(`--b2chat-${varName}`, value);
    return value;
  }

  function setVhUnit() {
    const vh = window.innerHeight * 0.01;
    setCSSVar("vh", `${vh}px`);
  }

  let mounted = false;
  onMount(() => {
    mounted = true;

    if (browser.satisfies({ mobile: { safari: ">=15" } })) {
      setCSSVar("window-bottom-gap", "3rem");
    }
    if (browser.getPlatformType(true) === "desktop") {
      setCSSVar("window-right-gap", "1rem");
      setCSSVar("window-bottom-gap", "1rem");
    }
  });

  $: if (mounted) {
    const setVhUnitDebounced = debounce(setVhUnit, 700);
    window.addEventListener("resize", setVhUnit, { capture: true });

    ["focus", "blur", "touchstart"].forEach(eventName => {
      window.addEventListener(eventName, setVhUnitDebounced, { capture: true });
    });

    setVhUnit();
  }

  $: if (mounted) {
    try {
      if ($theme.primary) {
        const primary = ColorContrastCalc.colorFrom(
          $theme.primary || theme.initialValue.primary
        );
        const primaryForeground =
          primary.contrastRatioAgainst(b2chatGray[50]) + 4 >
          primary.contrastRatioAgainst(b2chatGray[900])
            ? b2chatGray[50]
            : b2chatGray[900];

        setCSSVar("primary", primary.hexCode);
        setCSSVar("primary-foreground", primaryForeground);

        if (primary.contrastLevel(Color.white) === "-") {
          setCSSVar("highlight", b2chatGray.DEFAULT);
        } else {
          setCSSVar("highlight", primary.hexCode);
        }
      }

      if ($theme.marginBottom) {
        setCSSVar("margin-bottom", $theme.marginBottom);
        setCSSVar(
          "bottom-radius",
          $theme.marginBottom.match(/\d+/)?.[0] === "0" ? "0px" : "26px"
        );
      }

      if ($theme.marginRight) setCSSVar("margin-right", $theme.marginRight);
    } catch (e) {
      console.error(e);
    }
  }
</script>

<slot />

<style lang="postcss">
  :global(:root) {
    /* b2chat main colors */
    --b2chat-basefont: 16px;
    --b2chat-primary: #4c33ff;
    --b2chat-secondary: #323444;
    --b2chat-highlight: #4c33ff;
    --b2chat-primary-foreground: #ffffff;
    --b2chat-secondary-foreground: #ffffff;
    --b2chat-background: #ffffff;
    --b2chat-widdow-right-gap: 0px;
    --b2chat-window-bottom-gap: 0px;
    --b2chat-margin-right: 0px;
    --b2chat-margin-bottom: 0px;
    --b2chat-bottom-radius: 26px;
    --b2chat-vh: 1px;
  }
</style>

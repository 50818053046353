function debounce<F extends (...args: any[]) => any>(fn: F, wait: number) {
  let cacheArgs: Parameters<F>;
  let timer: NodeJS.Timeout;

  return (...args: Parameters<F>): void => {
    cacheArgs = args;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...cacheArgs);
    }, wait);
  };
}

export default debounce;

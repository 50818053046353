import type { Unwrap, Writable } from "./writableExtendable";

const addAsyncUpdate = <W extends Writable>(store: W) => {
  return {
    async asyncUpdate(updater: ($value: Unwrap<W>) => Promise<Unwrap<W>>) {
      store.set(await updater(store.read() as any));
    },
  };
};

export default addAsyncUpdate;

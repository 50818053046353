import countries from "../locales/countries.json";

function getCurrentCountry() {
  try {
    const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
    const country = countries
      .slice(0, 24)
      .find(country => country.time_zones?.includes(timeZone));

    if (!country) throw new Error("Country can not be deduced");

    return country;
  } catch (error) {
    console.log(error);
  }

  return countries.find(country => country.name_es === "Colombia")!;
}

export default getCurrentCountry;

module.exports = {
  DEFAULT: "#323444",
  50: "#F2F2F5",
  100: "#E5E5EC",
  200: "#B8BBCB",
  300: "#8C90AB",
  400: "#636787",
  500: "#43465B",
  600: "#3A3D4F",
  700: "#323444",
  800: "#292B38",
  900: "#20222C",
};

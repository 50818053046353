import browser from "@utils/browser";
import { version } from "@utils/constants";
import domContentLoaded from "@utils/domContentLoaded";
import LivechatError from "@utils/LivechatError";
import logger from "@utils/logger";
import timeout from "@utils/timeout";
import type { Theme } from "@utils/types";
import config from "src_launcher/store/model/config";
import type { SvelteComponent } from "svelte";
import { get } from "svelte/store";
import { P } from "ts-toolbelt/out/Object/_api";
import App from "./App.svelte";
import store from "./store";

class B2ChatInstance implements IB2ChatInstance {
  token: string;
  app?: SvelteComponent;

  /**
   * used to init the B2Chat livechat
   * @example
   * <script id="b2chat-livechat" src="live/livechat_launcher.js" defer async></script>
   * <script>
   * function b2chat_livechat_setup(B2Chat) { B2Chat.init({ token: "CLIENT_TOKEN" }) }
   * </script>
   */
  init(options: B2ChatInitOptions) {
    (async () => {
      if (this.app) throw new LivechatError("A livechat is currently running");

      options = typeof options === "string" ? { token: options } : options;
      this.token = options.token.trim();
      while (true) {
        try {
          await store.rootModel.config.fetchData(this.token);
          break;
        } catch {
          await timeout(10_000);
        }
      }
      this.create();
    })();
  }

  create() {
    if (this.app) return;

    this.app = new App({ target: document.body });
  }

  async ready() {
    await store.rootModel.config.isReady();
  }

  async closeSession() {
    await store.rootViewModel.websocketClient.closeSession();
    this.open = false;
  }

  toggle() {
    return store.rootModel.windowOpen.toggle();
  }

  get open(): boolean {
    return store.rootModel.windowOpen.read();
  }

  set open(value: boolean) {
    store.rootModel.windowOpen.set(value);
  }

  get theme(): Theme {
    return { ...store.rootModel.theme.read() };
  }

  set theme(theme: Partial<Theme>) {
    store.rootModel.config.merge(
      ({ headerColor, marginBottom, marginRight }) => ({
        headerColor: theme.primary || headerColor,
        marginBottom: theme.marginBottom || marginBottom,
        marginRight: theme.marginRight || marginRight,
      })
    );
  }

  get version() {
    return version;
  }

  get debug() {
    return get(logger.enabled);
  }

  set debug(value: boolean) {
    logger.enabled.set(value);
  }

  get browserInfo() {
    return browser.getResult();
  }

  get config() {
    return config.read();
  }
}

export function setupB2ChatInstance() {
  const B2Chat = new B2ChatInstance();

  try {
    Object.defineProperty(window, "B2Chat", {
      configurable: true,
      writable: false,
      value: B2Chat,
    });
  } catch (e) {
    console.log(e);
  }

  configureLegacyInit();

  return domContentLoaded().then(() => {
    window?.b2chat_livechat_setup?.(B2Chat);
  });
}

/**
 * This function add legacy init compatibility
 * @example
 * <script type="text/javascript" src="live/b2chat-min.js"></script>
 * <script>
 * _jq(window).bind("load", function() {B2Chat.init('CLIENT_TOKEN')})
 * </script>
 */
function configureLegacyInit() {
  if ("_jq" in window) return;

  try {
    Object.defineProperty(window, "_jq", {
      writable: false,
      configurable: true,
      value: () => ({
        bind(_listener: "load", handler: () => void) {
          domContentLoaded().then(handler);
        },
      }),
    });
  } catch (e) {
    console.log(e);
  }
}

import type { Env } from "./types";

const LivechatScript = (() => {
  const document = window.frameElement
    ? window.parent.document
    : window.document;

  const script = document.querySelector<HTMLScriptElement>("#b2chat-livechat");
  if (script) return script;

  const script_legacy = Array.from(
    document.querySelectorAll<HTMLScriptElement>("script")
  ).find(
    script =>
      script.src?.endsWith("b2chat-min.js") ||
      script.src?.endsWith("livechat_launcher.js")
  );

  console.assert(script_legacy, "[livechat] script legacy not found");

  return script_legacy!;
})();

export const ENV = <Env>LivechatScript.dataset.env || "prod";
export const RESOURCES_URL = new URL(LivechatScript.src).origin;
export const { SERVICES_URL } = {
  local: {
    SERVICES_URL: process.env.SERVICES_URL || "https://localhost:3030",
  },
  dev: {
    SERVICES_URL: "https://livechat.b2chat.io",
  },
  test: {
    SERVICES_URL: "https://livechat.b2chat.io",
  },
  prod: {
    SERVICES_URL: "https://livechat.b2chat.io",
  },
}[ENV];
export const version = process.env.VERSION || "";

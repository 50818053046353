import { get, writable } from "svelte/store";

const getId = (
  (i = 0) =>
  () =>
    ++i
)();

export type ToastVariant = "info" | "warning" | "progress";

export type ToastMessage = {
  message: string;
  variant: ToastVariant;
  delay?: number;
  deleted?: boolean;
};

export type ToastRef = {
  id: number;
  hide(): Promise<void>;
};

export const toastStore = (() => {
  const store = writable(new Map<number, ToastMessage>());

  return {
    ...store,
    add(toast: ToastMessage) {
      const id = getId();
      store.update($store => {
        $store.set(id, toast);
        return $store;
      });
      return id;
    },
    remove(id: number) {
      return new Promise<void>(resolve => {
        const $store = get(store);
        const message = $store.get(id);

        if (message && !message.deleted) {
          $store.set(id, { ...message, deleted: true });
          store.set($store);

          setTimeout(() => {
            $store.delete(id);
            store.set($store);
            resolve();
          }, 205);
        } else {
          resolve();
        }
      });
    },
  };
})();

function showToast(
  variant: ToastVariant,
  message: string,
  options?: { delay?: number; duration?: number }
): ToastRef {
  const id = toastStore.add({ variant, message, ...options });

  const hide = () => toastStore.remove(id);

  const { delay, duration } = options || {};

  if (duration && duration > 0) {
    setTimeout(hide, (delay || 0) + duration);
  }

  return {
    id,
    hide,
  };
}

export default showToast;

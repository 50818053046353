import noop from "@utils/noop";
import equal from "fast-deep-equal";
import { get, Writable } from "svelte/store";
import type { MessageListenerType } from "../createMessageListener";
import type { PostMessageType } from "../createPostMessage";
import type { EventRecord } from "../types";

export type BindWritable<T> = Writable<T> & {
  sync(): void;
  value(): T;
};

export function bindWritable<E extends EventRecord<`bind:${string}`, any>>(
  postMessage: PostMessageType<any>,
  messageListener: MessageListenerType<any>
): <N extends keyof E>(name: N, store: Writable<E[N]>) => BindWritable<E[N]>;

export function bindWritable(
  postMessage: PostMessageType<any>,
  messageListener: MessageListenerType<any>
) {
  return (name: string, store: Writable<any>) => {
    let silent = true;

    messageListener(name, ev => {
      if (ev.data?.sync) {
        postMessage(name, get(store));
      } else if ("update" in store) {
        store.update($value => {
          silent = true;
          return !equal($value, ev.data.value) ? ev.data.value : $value;
        });
      }
    });

    store.subscribe($value => {
      silent ? (silent = false) : postMessage(name, $value);
    });

    return {
      ...store,
      value() {
        return get(store);
      },
      sync:
        "update" in store
          ? () => postMessage(name, undefined, { sync: true })
          : noop,
    };
  };
}

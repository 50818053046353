import { RESOURCES_URL } from "@utils/constants";
import createMessageListener, {
  MessageListenerType,
} from "@utils/createMessageListener";
import createPostMessage, { PostMessageType } from "@utils/createPostMessage";
import LivechatError from "@utils/LivechatError";
import logger from "@utils/logger";
import { bindWritable } from "@utils/storeUtils/bindWritable";
import type {
  EventRecord,
  ILivechatMessage,
  ILivechatSession,
  LivechatReadyState,
  TimestampKey,
} from "@utils/types";
import browserTabBadge, {
  BrowserTabBadge,
} from "src_launcher/components/browserTabBadge";
import showToast, { ToastRef } from "src_window/components/Toast/toast";
import { Locale } from "src_window/locales";
import type {
  ErrorEventRecord,
  ReceiveMessageEventRecord,
  SendMessageEventRecord,
} from "src_worker/types";
import { t } from "svelte-i18n";
import { get, Readable, writable } from "svelte/store";
import type { RootModel } from "../model";
import type MessagesViewModel from "./MessagesViewModel";
import type SessionViewModel from "./SessionViewModel";

const workerConstructor = () => {
  if ("Worker" in window) {
    logger.debug("Worker API available");
    return Worker;
  }

  throw new LivechatError("Not Worker API Supported in this navigator");
};

function instanceOfSharedWorker(
  worker: SharedWorker | Worker
): worker is SharedWorker {
  try {
    return worker instanceof SharedWorker;
  } catch {}
  return false;
}

async function workerScriptURL() {
  try {
    const script = await (
      await fetch(`${RESOURCES_URL}/live/livechat_worker.js`)
    ).text();
    const file = new File([script], "workerscript.js", {
      type: "application/javascript",
    });
    return URL.createObjectURL(file);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export default class WebsocketClientViewModel {
  config: RootModel["config"];
  prechat: RootModel["prechat"];
  windowOpen: RootModel["windowOpen"];
  windowFocus: RootModel["windowFocus"];

  readyState: Readable<LivechatReadyState>;
  unreadCount: BrowserTabBadge;

  private worker: SharedWorker | Worker;
  private workerPostMessage: PostMessageType<SendMessageEventRecord>;
  private workerMessageListener: MessageListenerType<
    ReceiveMessageEventRecord & ErrorEventRecord
  >;

  constructor(
    rootModel: RootModel,
    private sessionViewModel: SessionViewModel,
    private messagesViewModel: MessagesViewModel
  ) {
    this.config = rootModel.config;
    this.windowOpen = rootModel.windowOpen;
    this.windowFocus = rootModel.windowFocus;
    this.prechat = rootModel.prechat;
    this.init();
  }

  async init() {
    //#region SharedWorker setup
    const Worker = workerConstructor();

    const { token } = await this.config.isReady();

    this.worker = new Worker(await workerScriptURL(), {
      name: token,
    });

    const workerSource = (() => {
      const { worker } = this;
      if (instanceOfSharedWorker(worker)) {
        const { port } = worker;
        port.start();
        return {
          messagePort: () => port,
          eventTarget: () => port,
        };
      }
      return {
        messagePort: () => worker,
        eventTarget: () => worker,
      };
    })();
    //#endregion

    this.workerPostMessage = createPostMessage(workerSource.messagePort);
    this.workerMessageListener = createMessageListener(
      workerSource.eventTarget
    );
    const workerBindMessage = bindWritable<
      EventRecord<"bind:session", ILivechatSession> &
        EventRecord<"bind:readyState", LivechatReadyState> &
        EventRecord<"bind:debug", boolean>
    >(this.workerPostMessage, this.workerMessageListener);

    //#region session bind
    workerBindMessage("bind:session", this.sessionViewModel.session);
    this.windowOpen.subscribe($open => {
      if ($open && !this.config.read().preChatEnabled) {
        this.openSession();
      }
    });
    //#endregion

    //#region Unread message counter
    this.unreadCount = browserTabBadge();

    this.windowFocus.subscribe($windowFocus => {
      if ($windowFocus && this.windowOpen.read()) {
        this.unreadCount.reset();
      }
    });

    this.windowOpen.subscribe($open => {
      if ($open) {
        this.unreadCount.reset();
      }
    });

    this.workerMessageListener("receive:message", ev => {
      if (
        (!this.windowOpen.read() || !this.windowFocus.read()) &&
        ev.data.value?.from === "incoming"
      ) {
        this.unreadCount.increase();
      }
    });
    //#endregion

    //#region Websocket setup
    const readyState = workerBindMessage(
      "bind:readyState",
      writable<LivechatReadyState>("CLOSED")
    );
    readyState.subscribe($readyState => this.onReadyStateChanged($readyState));
    readyState.sync();
    this.readyState = { subscribe: readyState.subscribe };

    this.workerMessageListener("receive:message", ev =>
      this.onReceiveMessage(ev.data.value)
    );

    this.workerMessageListener("error:sendingMessage", ev =>
      this.onErrorSendingMessage(ev.data.value.key)
    );
    //#endregion

    Locale.isReady().then(() => {
      let toast: ToastRef | undefined;

      this.readyState.subscribe($readyState => {
        toast?.hide();
        if ($readyState === "CONNECTING") {
          const $t = get(t);
          toast = showToast("progress", $t("websocket:connecting"), {
            delay: 250,
          });
        }
      });
    });

    workerBindMessage("bind:debug", logger.enabled);
  }

  private async onReceiveMessage(message: ILivechatMessage) {
    await this.messagesViewModel.put(message.timestamp, message);
  }

  private async onErrorSendingMessage(key: TimestampKey) {
    await this.messagesViewModel.update(key, { sent: false });
  }

  private async onReadyStateChanged(readyState: LivechatReadyState) {
    if (readyState === "OPEN") {
      this.messagesViewModel.messages.read().forEach(message => {
        if (message.from === "outgoing" && !message.sent)
          this.sendMessage(message);
      });
    }
  }

  async sendMessage(message: ILivechatMessage) {
    try {
      message.sent = true;
      await this.messagesViewModel.put(message.timestamp, message);

      if (message.from === "outgoing")
        this.workerPostMessage("send:message", message);
    } catch (e) {
      console.error(e);
    }
  }

  async openSession() {
    try {
      this.prechat.setFormError("");

      const { preChatEnabled } = await this.config.readWhen(
        ({ loading }) => !loading
      );

      const { prevSession, nextSession } =
        await this.sessionViewModel.getSession(preChatEnabled, this.prechat);

      if (prevSession.contactId !== nextSession.contactId) {
        await this.messagesViewModel.reset();
      }
    } catch (e) {
      this.prechat.setFormError(e.message);
    }
  }

  async closeSession() {
    this.unreadCount.reset();
    await this.messagesViewModel.reset();
    this.sessionViewModel.clearSession();
  }
}

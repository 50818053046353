import { Locale } from "src_window/locales";
import { t } from "svelte-i18n";
import type { MessageFormatter } from "svelte-i18n/types/runtime/types";
import { get, Writable } from "svelte/store";
import type { O } from "ts-toolbelt";
import addMerge from "./storeUtils/addMerge";
import writableExtendable from "./storeUtils/writableExtendable";

export type Field<T = unknown, F extends Form = Form> = {
  default: T;
  value: T;
  error: boolean;
  errorMessage: string;
  validate(
    value: T,
    $t: MessageFormatter,
    form: F
  ): string | void;
  visible: boolean;
};

export type Form<T extends Record<string, unknown> = any> = {
  fields: { [K in keyof T]: Field<T[K], Form<T>> };
  readonly valid: boolean;
  errorMessage: string;
  submitCount: number;
};

export type WritableForm<T extends Record<string, unknown>> = Writable<
  Form<T>
> & {
  reset(): void;
  values(): T;
  form(): O.Readonly<Form<T>, "deep">;
  setFormError(errorMessage: string): void;
  increaseSubmitCount(): void;
};

export function createForm<T extends Record<string, unknown>>(init: {
  [K in keyof T]: Pick<Field<T[K], Form<T>>, "value" | "validate">;
}): WritableForm<T> {
  const form = writableExtendable<Form<T>>({
    valid: false,
    errorMessage: "",
    submitCount: 0,
    fields: Object.entries(init).reduce((fields, [name, field]) => {
      fields[name as keyof T] = {
        ...field,
        default: field.value,
        error: true,
        errorMessage: "",
        visible: true,
      };
      return fields;
    }, <Form<T>["fields"]>{}),
  }).extend(addMerge);

  Locale.isReady().then(() => {
    form.subscribe($form => {
      let changed = false;
      const fields = Object.values<Field<any>>($form.fields);

      fields
        .filter(field => field.visible)
        .forEach(field => {
          const validation = field.validate(field.value, get(t), $form);
          const error = typeof validation === "string" ? true : false;
          const errorMessage = validation || "";

          if (error !== field.error) {
            field.error = error;
            changed = true;
          }

          if (errorMessage !== field.errorMessage) {
            field.errorMessage = errorMessage;
            changed = true;
          }
        });

      const valid = !fields
        .filter(field => field.visible)
        .some(field => field.error);

      if (valid !== $form.valid) changed = true;

      if (changed) {
        form.merge({ valid });
      }
    });
  });

  return {
    ...form,
    reset() {
      resetForm(form);
    },
    form() {
      return form.read();
    },
    values() {
      return getFormValues(form.read());
    },
    setFormError(errorMessage: string) {
      form.merge({ errorMessage });
    },
    increaseSubmitCount() {
      form.merge(({ submitCount }) => ({ submitCount: submitCount + 1 }));
    },
  };
}

function getFormValues<T extends Form>(
  form: T
): T extends Form<infer U> ? U : unknown {
  return Object.entries(form.fields).reduce((formValues, [name, { value }]) => {
    formValues[name] = value;
    return formValues;
  }, <any>{});
}

function resetForm<T extends Form>(form: Writable<T>) {
  form.update($form => {
    Object.values<Field<any>>($form.fields).forEach(field => {
      field.value = field.default;
    });

    return { ...$form, errorMessage: "", submitCount: 0 };
  });
}

import { ENV } from "@utils/constants";
import { createForm } from "@utils/formStore";
import * as EmailValidator from "email-validator";
import config from "./config";
import getCurrentCountry from "src_window/utils/getCurrentCountry";

export type Prechat = {
  name: string;
  email: string;
  phone: string;
  terms: boolean;
  dialCode: string;
};

export const guestPrechat = {
  name: "guest",
  email: "guest@b2chat.io",
  phone: "",
  terms: false,
  dialCode: "+57",
};

export const initialPrechat: Prechat =
  ENV === "dev"
    ? {
        name: "smith",
        email: "smith@gmail.com",
        phone: "3197592490",
        terms: false,
        dialCode: getCurrentCountry()?.dial_code || "",
      }
    : {
        name: "",
        email: "",
        phone: "",
        terms: false,
        dialCode: getCurrentCountry()?.dial_code || "",
      };

const isEmpty = (text: string) => !text.trim();

export const prechat = createForm<Prechat>({
  name: {
    value: initialPrechat.name,
    validate(value, $t) {
      if (isEmpty(value)) return $t("prechat:field:required");
    },
  },
  email: {
    value: initialPrechat.email,
    validate(value, $t) {
      if (isEmpty(value)) return $t("prechat:field:required");

      if (!EmailValidator.validate(value)) return $t("prechat:email:error");
    },
  },
  phone: {
    value: initialPrechat.phone,
    validate(value, $t, form) {
      const dialCode = form.fields.dialCode.value;

      if (isEmpty(value)) return $t("prechat:field:required");

      if (!validatePhoneByCountry(dialCode, value)) {
        return $t("prechat:phone:error");
      }
    },
  },
  dialCode: {
    value: initialPrechat.dialCode,
    validate() {},
  },
  terms: {
    value: initialPrechat.terms,
    validate(value, $t) {
      if (!value) return $t("prechat:field:required");
    },
  },
});

config.subscribe(({ loading, habeasdataText }) => {
  if (loading) return;
  prechat.update($form => {
    $form.fields.terms.visible = (habeasdataText?.length || 0) > 0;
    return { ...$form };
  });
});

function validatePhoneByCountry(dialCode: string, value: string): boolean {
  value = value.replace(/\s/g, "");

  if (dialCode === "+51") {
    //Perú
    return value.length === 9;
  }

  if (dialCode === "+593") {
    //Ecuador
    return value.length === 9;
  }

  if (dialCode === "+591") {
    //Bolivia
    return value.length === 8;
  }

  if (dialCode === "+506") {
    //Costa rica
    return value.length === 8;
  }

  return value.length >= 8 && value.length <= 12; //Otros
}

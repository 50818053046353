import { get, Writable as SvelteWritable, writable } from "svelte/store";
import cloneDeep from "../cloneDeep";

export type Unwrap<W> = W extends Writable<infer T> ? T : unknown;

export type WritableExtendCallback<W, E> = (store: W) => E | void;

export interface Writable<T = unknown> extends SvelteWritable<T> {
  read(): T;
}

export type WritableExtendable<T, W extends Writable<T>> = Omit<W, "extend"> & {
  extend<E extends object>(
    cb: WritableExtendCallback<W, E>
  ): WritableExtendable<T, W & E>;
};

export default function writableExtendable<
  T,
  W extends Writable<T> = Writable<T>
>(value: T | SvelteWritable<T>): WritableExtendable<T, W> {
  const store = writable(value);

  return writableExtendable_({
    ...store,
    read() {
      return get(store);
    },
  });
}

export function extendSvelteWritable<T, W extends Writable<T> = Writable<T>>(
  store: SvelteWritable<T>
): WritableExtendable<T, W> {
  return writableExtendable_({
    ...store,
    read() {
      return get(store);
    },
  });
}

function writableExtendable_(store: any) {
  return {
    ...store,
    extend(cb: Function) {
      const storeExtended = { ...store, ...cb(store) };
      return writableExtendable_(storeExtended);
    },
  };
}

export const withReset = <W extends Writable>(store: W) => {
  const initialValue = cloneDeep(store.read());

  return {
    initialValue,
    reset() {
      store.set(initialValue);
    },
  };
};

import fetchLivechatConfig from "@utils/services/livechatConfig";
import addMerge from "@utils/storeUtils/addMerge";
import addReadWhen from "@utils/storeUtils/addReadWhen";
import writableExtendable from "@utils/storeUtils/writableExtendable";
import type { LivechatConfigData } from "@utils/types";

export type Config = LivechatConfigData & { loading: boolean };

const config = writableExtendable<Config>({
  loading: true,
  token: "",
  botId: 0,
  merchantName: "",
  preChatEnabled: false,
  emptyTimeline: "",
  emptyTimelineEn: "",
  habeasdataText: "",
  habeasdataTextEn: "",
  habeasdataUrl: "",
  habeasdataUrlEn: "",
  prechatInfo: "",
  prechatInfoEn: "",
  headerColor: "",
  headerImage: "",
  linkImage: "",
  marginBottom: "",
  marginRight: "",
})
  .extend(addMerge)
  .extend(addReadWhen)
  .extend(store => ({
    isReady() {
      return store.readWhen($config => !$config.loading);
    },
    async fetchData(token: string) {
      store.merge({ loading: true });
      const data = await fetchLivechatConfig(token);
      store.set({ ...data, token, loading: false });
    },
  }));

export default config;

import DOMPurify from "dompurify";
import marked, { Token } from "marked";

DOMPurify.addHook("uponSanitizeElement", (node, element) => {
  console.log(node, element);
  if (element.tagName === "a") {
    node.setAttribute("target", "_blank");
  }
  return node;
});

marked.setOptions({
  sanitizer: DOMPurify.sanitize,
});

marked.use({
  renderer: {
    heading(text, level) {
      return `<h${level}>${text}</h${level}>`;
    },
    link(href, title, text) {
      return `<a href="${href}" title="${
        title || href
      }" target="_blank" rel="noreferrer">${text || href}</a>`;
    },
  },
});

const underline = <const>{
  start: /\+\+/,
  content: /^\+\+(?=\S)([\s\S]*?\S)\+\+(?!\+)/,
};

namespace Tokens {
  export type Underline = {
    type: "underline";
    raw: string;
    tokens: Token[];
  };
}
marked.use({
  extensions: [
    {
      name: "underline",
      level: "inline",
      start(src: string) {
        return src.match(underline.start)?.index as number; // Hint to Marked.js to stop and check for a match
      },
      tokenizer(src: string): Tokens.Underline | void {
        const match = underline.content.exec(src);

        if (match) {
          return {
            // Token to generate
            type: "underline", // Should match "name" above
            raw: match[0], // Text to consume from the source
            tokens: this.lexer.inlineTokens(match[1], []),
          };
        }
      },
      renderer(token: Tokens.Underline) {
        return `<u>${this.parser.parseInline(
          token.tokens,
          this.parser.renderer
        )}</u>`;
      },
    },
  ],
});

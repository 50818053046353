<script lang="ts">
  import { scale } from "svelte/transition";

  export let count = 0;

  let className = "";
  export { className as class };
</script>

{#if count > 0}
  <div
    class="badge {className}"
    transition:scale={{ duration: 250, opacity: 0 }}
  >
    <span class="text">
      {count > 9 ? "+9" : count}
    </span>
    <span class="ring" />
  </div>
{/if}

<style lang="postcss">
  .badge {
    @apply bg-red-400 text-white font-bold text-xs w-5 h-5 rounded-full pointer-events-none;
    position: absolute;
    inset: 0 auto auto 0;
    box-shadow: 0 0 4px 0 theme("colors.red.400");
  }

  .text {
    position: absolute;
    inset: 50% 50% auto auto;
    transform: translate(50%, -50%);
  }

  .ring {
    @apply absolute inset-0 border border-red-400 border-solid rounded-full;
    animation: 1s ease-out 0s infinite running ring;
  }

  @keyframes -global-ring {
    from {
      transform: scale(1);
      opacity: 1;
    }
    95% {
      opacity: 0;
    }
    to {
      transform: scale(1.8);
      opacity: 0;
    }
  }
</style>

import LivechatError from "@utils/LivechatError";
import logger from "./logger";
import type { DataMap, GetMessagePort } from "./types";

function instanceOfWindow(obj: any): obj is Window {
  try {
    return obj.constructor.name === "Window" || obj instanceof Window;
  } catch {}
  return false;
}

export default function createPostMessage<D extends DataMap>(
  messagePort: GetMessagePort,
  targetOrigin?: string
): PostMessageType<D>;

export default function createPostMessage(
  messagePort: GetMessagePort,
  targetOrigin: string = ""
) {
  return (name: string, value: any, extra?: { [key: string]: any }) => {
    try {
      const port = messagePort();
      if (!port) throw new LivechatError("MessagePort unavailable");

      const data: any = { name, ...extra };
      value !== undefined && (data.value = value);
      if (instanceOfWindow(port)) {
        port.postMessage(data, targetOrigin);
      } else {
        port.postMessage(data);
      }
      logger.debug("postMessage", data);
    } catch (e) {
      console.error(e);
    }
  };
}

export type PostMessageType<D extends DataMap> = <Name extends keyof D>(
  name: Name,
  value?: D[Name],
  extra?: Record<string, any>
) => void;

<svelte:options accessors />

<script lang="ts">
  import { RESOURCES_URL } from "@utils/constants";
  import store from "src_launcher/store";
  import Badge from "./components/Badge.svelte";
  import FloatingButton from "./FloatingButton.svelte";
  import ImagePreview from "./ImagePreview.svelte";
  import LivechatWindow from "./LivechatWindow.svelte";
  import Theme from "./Theme.svelte";

  const windowOpen = store.rootModel.windowOpen;
  const mediaPreview = store.rootModel.mediaPreview;
  const config = store.rootModel.config;
  const unreadCount = store.rootViewModel.websocketClient.unreadCount;
</script>

<svelte:head>
  <link rel="stylesheet" href="{RESOURCES_URL}/live/livechat_launcher.css" />
  <link rel="preconnect" href="https://fonts.gstatic.com" />
  <link
    href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Poppins&display=swap"
    rel="stylesheet"
  />
</svelte:head>

{#if !$config.loading}
  <Theme>
    <div id="livechat" data-open={$windowOpen}>
      <FloatingButton src={$config.linkImage} on:click={windowOpen.toggle}>
        <Badge count={$unreadCount} />
      </FloatingButton>
      <LivechatWindow />
    </div>
    <ImagePreview bind:open={$mediaPreview.open} src={$mediaPreview.imageSrc} />
  </Theme>
{/if}

<style lang="postcss" scoped>
  @import "../utils/svelteActions/ripple.css";

  #livechat {
    width: 0 !important;
    height: 0 !important;
    border: 0 !important;
    outline: 0 !important;
  }

  #livechat[data-open="true"] :global(#livechat-launcher) {
    @apply hidden;
  }
</style>

import type { Unwrap, Writable } from "./writableExtendable";

const addMerge = <W extends Writable>(store: W) => {
  type T = Unwrap<W>;

  return {
    merge(value: Partial<T> | (($store: T) => Partial<T>)) {
      store.update(($store: any) => {
        return {
          ...$store,
          ...(typeof value === "function" ? value($store) : value),
        };
      });
    },
  };
};

export default addMerge;

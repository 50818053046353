import equal from "fast-deep-equal";
import type { Writable } from "./writableExtendable";

function read<T>(strValue: string | null | undefined, defaultValue: T): T {
  try {
    return typeof strValue === "string" ? JSON.parse(strValue) : defaultValue;
  } catch {}
  return defaultValue;
}

const bindStorage =
  <K extends string>(key: K, storage?: Storage) =>
  <W extends Writable & { initialValue: unknown }>(store: W) => {
    try {
      storage ??= window.localStorage;
      store.set(read(storage.getItem(key), store.initialValue));

      let silent = false;
      window.addEventListener("storage", ev => {
        if (ev.storageArea === storage && ev.key === key) {
          const value = read(ev.newValue, store.initialValue);

          if (!equal(value, store.read())) {
            silent = true;
            store.set(value);
          }
        }
      });

      store.subscribe($value => {
        if (silent) {
          silent = false;
        } else {
          try {
            storage!.setItem(key, JSON.stringify($value));
          } catch (e) {
            console.error(e);
          }
        }
      });
    } catch {}

    return { storageKey: key, ...store };
  };

export default bindStorage;

import addMerge from "@utils/storeUtils/addMerge";
import addReadWhen from "@utils/storeUtils/addReadWhen";
import addReset from "@utils/storeUtils/addReset";
import bindStorage from "@utils/storeUtils/bindStorage";
import writableExtendable from "@utils/storeUtils/writableExtendable";
import type { ILivechatSession } from "@utils/types";
import { derived, readable } from "svelte/store";

const session = writableExtendable<ILivechatSession>({
  ok: false,
  contactId: "",
  wsUrl: "",
  closedAt: -1,
})
  .extend(addReadWhen)
  .extend(addReset)
  .extend(bindStorage("livechat:session"))
  .extend(addMerge)
  .extend(session => {
    const remainingTime = (closedAt: number) =>
      Math.max(0, 10_000 - (Date.now() - closedAt));

    const { closedAt } = session.read();

    const startCloseTimeout = (closedAt: number) =>
      setTimeout(() => {
        if (session.read().closedAt !== -1) session.reset();
      }, remainingTime(closedAt));

    if (closedAt !== -1) startCloseTimeout(closedAt);

    return {
      closeImmediately() {
        session.reset();
      },
      startCloseCountDown() {
        const closedAt = Date.now();

        session.merge({ closedAt });
        startCloseTimeout(closedAt);
      },
      get isClosing() {
        return derived(session, ({ ok, closedAt }) => !ok || closedAt !== -1);
      },
      get closeCountDown() {
        return derived(
          session,
          ({ closedAt }, set: (value: number) => void) => {
            set(remainingTime(closedAt));

            if (closedAt === -1) return;

            setTimeout(() => {
              set(remainingTime(closedAt));

              let timer: NodeJS.Timer;

              timer = setInterval(() => {
                const time = remainingTime(closedAt);
                set(time);
                if (time === 0) clearInterval(timer);
              }, 500);
            }, closedAt % 1000);
          }
        );
      },
    };
  });

export default session;

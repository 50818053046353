import type { WritableForm } from "@utils/formStore";
import {
  emptySession,
  fetchSession,
  fetchValidateSession,
} from "@utils/services/livechatSession";
import type { RootModel } from "../model";
import { guestPrechat, Prechat } from "../model/prechat";

export default class SessionViewModel {
  session: RootModel["session"];
  config: RootModel["config"];

  constructor(rootModel: RootModel) {
    this.session = rootModel.session;
    this.config = rootModel.config;
  }

  isOk() {
    return this.session.read().ok;
  }

  async getSession(prechatEnabled: boolean, prechat: WritableForm<Prechat>) {
    try {
      const prevSession = this.session.read();
      const { habeasdataText, token } = this.config.read();
      const nextSession = await Promise.resolve().then(async () => {
        if (prevSession.ok)
          return fetchValidateSession(token, prevSession.contactId);
        if (prechatEnabled) {
          return prechat.form().valid
            ? fetchSession(token, prechat.values(), habeasdataText)
            : emptySession;
        }

        return fetchSession(token, guestPrechat, habeasdataText);
      });

      if (prevSession.contactId !== nextSession.contactId) {
        this.session.set(nextSession);
      }

      return { prevSession, nextSession };
    } catch (e) {
      this.clearSession();
      throw e;
    }
  }

  clearSession() {
    this.session.reset();
  }
}

import addReadWhen from "@utils/storeUtils/addReadWhen";
import writableExtendable from "@utils/storeUtils/writableExtendable";

export type RippleParams = { className?: string };

export default (node: HTMLElement, params?: RippleParams) => {
  let className = params?.className;
  const mousedown = writableExtendable(false).extend(addReadWhen);

  function onMouseUp() {
    mousedown.set(false);
  }

  function onMouseDown(this: HTMLElement, ev: MouseEvent) {
    mousedown.set(true);
    if (this.hasAttribute("disabled")) return;

    const ripple = document.createElement("span");
    const radius = Math.max(this.clientWidth, this.clientHeight) / 2;
    const bound = this.getBoundingClientRect();
    ripple.style.width = `${radius * 2}px`;
    ripple.style.height = `${radius * 2}px`;
    ripple.style.top = `${ev.clientY - bound.top - radius}px`;
    ripple.style.left = `${ev.clientX - bound.left - radius}px`;

    ripple.addEventListener("animationend", ev => {
      if (ev.animationName === "b2chat-ripple") {
        mousedown
          .readWhen(v => !v)
          .then(() => ripple.classList.add("b2chat-ripple-fadeout"));
      } else if (ev.animationName === "b2chat-ripple-fadeout") {
        this.removeChild(ripple);
      }
    });
    ripple.classList.add("b2chat-ripple");
    if (className) ripple.classList.add(className);

    this.appendChild(ripple);
  }

  node.addEventListener("mousedown", onMouseDown, { capture: true });
  document.addEventListener("mouseup", onMouseUp, { capture: true });

  return {
    update(params: RippleParams) {
      className = params?.className;
    },
    destroy() {
      node.removeEventListener("mousedown", onMouseDown, { capture: true });
      document.removeEventListener("mouseup", onMouseUp, { capture: true });
    },
  };
};

import cloneDeep from "@utils/cloneDeep";
import type { Unwrap, Writable } from "./writableExtendable";

const addReset = <W extends Writable>(store: W) => {
  const initialValue = cloneDeep(store.read() as Unwrap<W>);

  return {
    initialValue,
    reset() {
      store.set(initialValue);
    },
  };
};

export default addReset;

import { SERVICES_URL } from "@utils/constants";
import LivechatError from "@utils/LivechatError";
import type { ContactData, ILivechatSession, UserInfoData } from "@utils/types";
import type { Prechat } from "src_launcher/store/model/prechat";

export const emptySession: ILivechatSession = {
  ok: false,
  contactId: "",
  wsUrl: "",
};

function fixWSUrl(url: string) {
  if (/^<.*>$/.test(url)) {
    return url.slice(1, -1);
  }
  return url;
}

export async function fetchSession(
  token: string,
  prechat: Prechat,
  habeasdataText: string
): Promise<ILivechatSession> {
  try {
    const response = await fetch(`${SERVICES_URL}/live/api/v1/userInfo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Customer-Id": token,
      },
      cache: "no-cache",
      mode: "cors",
      body: JSON.stringify(<UserInfoData>{
        name: prechat.name,
        email: prechat.email,
        ...(prechat.phone && {
          phonenumber: prechat.dialCode + prechat.phone.replace(/ /g, ""),
        }),
        fullTextHabeasData: habeasdataText || "",
        habeasdata: "true",
        comments: "",
        ipClient: "",
      }),
    });
    const data = await LivechatError.assertResponse<ContactData>(response);

    if (data.status === "OK")
      return {
        ok: true,
        contactId: data.contactId || "",
        wsUrl: fixWSUrl(data.wsUrl),
        closedAt: -1,
      };
  } catch (e) {
    throw e;
  }

  return emptySession;
}

export async function fetchValidateSession(
  token: string,
  contactId: string
): Promise<ILivechatSession> {
  try {
    const response = await fetch(`${SERVICES_URL}/live/api/v1/validateKey`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Customer-Id": token,
      },
      cache: "no-cache",
      mode: "cors",
      body: JSON.stringify({
        contactId,
      }),
    });

    const data: ContactData = await LivechatError.assertResponse(response);

    if (data.contactExist)
      return {
        ok: true,
        contactId,
        wsUrl: fixWSUrl(data.wsUrl),
        closedAt: -1,
      };
  } catch (e) {
    throw e;
  }

  return emptySession;
}

import rootModel from "../model";
import MessagesViewModel from "./MessagesViewModel";
import SessionViewModel from "./SessionViewModel";
import WebsocketClientViewModel from "./WebsocketClient";

const session = new SessionViewModel(rootModel);
const messages = new MessagesViewModel(rootModel);
const websocketClient = new WebsocketClientViewModel(
  rootModel,
  session,
  messages
);

const rootViewModel = {
  session,
  messages,
  websocketClient,
};

export default rootViewModel;
